import axios from "../../lib/axios";

export async function login(data) {
    const response = await axios.post('/auth/login', data);
    return response;
}

export async function logout() {
    const response = await axios.post('/auth/logout');
    return response;
}

export async function forgotPassword(data) {
    const response = await axios.post('/auth/password/email', data);
    return response;
}

export async function resetPassword(data) {
    const response = await axios.post('/auth/password/reset', data);
    return response;
}

export async function user(getOnlyUser = false) {
    if (getOnlyUser == true) {
        const response = await axios.get('/user?profile=1');
        return response.data;
    } else {
        const response = await axios.get('/user');
        return response.data;
    }
}