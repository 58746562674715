import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import styles from "../../styles/steps.module.scss";
import loginImage from '../../assets/images/login-image.png';
import SignInSchema from '../../services/Schemas/SignInSchema';
import {login as doLogin, user as getUser, logout as doLogout} from "../../services/Apis/Auth.service";
import {Link, useNavigate} from "react-router-dom";
import LayoutWithoutHeader from "../../components/FrontLayout/LayoutWithoutHeader";
import {Image} from "react-bootstrap";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from "react-auth-kit/hooks/useSignOut";
import {get as getAgreement} from "../../services/Apis/Front/Agreements.service";
import Loading from "../../components/Loading";

function SignIn(props: any) {

    let navigate = useNavigate();

    const signingIn = useSignIn();
    const isAuthenticated = useIsAuthenticated();
    const signOut = useSignOut();

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState<null | string>();
    const [isLoading, setIsLoading] = useState(true);

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(SignInSchema)
    });

    useEffect( () => {

        if (isAuthenticated) {
            (async () => {
                if (sessionStorage.getItem('logout_user')) {
                    signOut();
                    sessionStorage.removeItem('logout_user');
                }
                else
                {
                    const data = await getUser();

                    if (data.agreement.status == "Agreement Signature Waiting") {
                        navigate('/aftale/'+ data.agreement.agreement_id +'/start', { replace: true });
                    } else {
                        navigate('/account/aftale/'+ data.agreement.agreement_id+'/dashboard', { replace: true });
                    }
                }

                setIsLoading(false);
            })();
        } else {
            setIsLoading(false);
        }

        document.body.classList.add('white-background');

        if (sessionStorage.getItem('showResetMessage')) {
            setShowSuccess(sessionStorage.getItem('showResetMessage'));
            sessionStorage.removeItem('showResetMessage')
        }
    }, []);

    const handleLogin = async (data: any) => {

        setShowError(false);
        setShowSuccess(null);

        try {
            setIsLoading(true);

            const response = await doLogin(data);

            if (response.status === 422) {
                setShowError(true);
                setIsLoading(false);
            } else if (response.status === 406) {
                doLogout();
                handleLogin(data);
            } else {
                const user = await getUser(true);

                if(signingIn({
                    auth: {
                        token: response.data.token,
                        type: 'Bearer'
                    },
                    userState: user
                })){
                    if (response.data.status === 'Agreement Signature Waiting') {
                        navigate('/aftale/'+ response.data.agreement +'/start');
                    } else {
                        navigate('/account/aftale/'+ response.data.agreement+'/dashboard');
                    }
                } else {
                    setIsLoading(false);
                    setShowError(true);
                }
            }

        } catch (err) {
            setIsLoading(false);
            setShowError(true);
        }
    }

  return (
      <>
          {isLoading &&
              <Loading/>
          }

          {!isLoading &&
              <LayoutWithoutHeader>
                  <>
                      <div className={styles['q-step-row']}>
                          <Container className={styles['container']}>
                              <div className={styles['q-step-small-container']}>
                                  <div className={`w-100 text-center`}><Image src={loginImage} /></div>
                                  <div className={`${styles['q-step-title']} text-start fs-3`}>Brug venligt de signup oplysninger vi har sendt i din indbakke!</div>
                                  {showError &&
                                      <p className="text-danger pt-4 fs-5">Oops! You have entered an invalid username or password.</p>
                                  }
                                  {showSuccess !== null &&
                                      <p className="text-success pt-4 fs-5">{ showSuccess }</p>
                                  }
                                  <form onSubmit={handleSubmit(handleLogin)}>
                                      <div className={styles['form-group']}>
                                          <input type="email" className={`${styles['form-control']} form-control`} {...register("email")} placeholder={"Email"} />
                                          <div className="invalid-feedback-error">{errors?.email?.message}</div>
                                      </div>
                                      <div className={styles['form-group']}>
                                          <input type="password" className={`${styles['form-control']} form-control`} {...register("password")} placeholder={"Adgangskode"} />
                                          <div className="invalid-feedback-error">{errors?.password?.message}</div>
                                      </div>
                                      <div className={styles['form-group']}>
                                          <button type="submit" className={`blue-btn d-block w-100 ${styles['blue-btn-full-width']}`}>OPRET DIN PROFIL 🔒</button>
                                      </div>
                                      <div className={`text-center d-block w-100 float-start pt-4`}>GLEMT PASSWORD? <Link to={'/forgot-password'}>NULSTIL</Link></div>
                                  </form>
                              </div>
                          </Container>
                      </div>
                  </>

              </LayoutWithoutHeader>
          }
      </>
  );
}

export default SignIn;
