import axios from "../../../lib/axios";

export async function getAll(id) {
    const response = await axios.get('/agreements/'+id+'/documents');
    return response;
}

export async function get(id, fileid) {
    const response = await axios.get('/agreements/'+id+'/documents/'+fileid, {
        responseType: 'blob'
    });
    return response;
}

export async function create(id, data) {
    const response = await axios.post('/agreements/'+id+'/documents', data);
    return response.data;
}

export async function destroy(id, fileid) {
    var data = [];
    data._method = 'DELETE';
    const response = await axios.delete('/agreements/'+id+'/documents/'+fileid, data);
    return response.data;
}