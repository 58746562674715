import { Link } from "react-router-dom";
import { Container, Dropdown } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../assets/images/logo-black.svg';
import styles from "../../../styles/_header.module.scss";
import {useNavigate} from "react-router-dom";
import {logout as doLogout} from "../../../services/Apis/Auth.service";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {useEffect} from "react";
import IUser from "../../../interfaces/IUser";


const Header = () => {
    const navigate = useNavigate();
    const signOut = useSignOut();
    const auth = useAuthUser() as IUser;

    const handleLogout = async function (e: any) {
        e.preventDefault();

        try {
            await doLogout();
            signOut();
            navigate('/');
        } catch (e) {
            alert("Something went wrong! Try again later.");
        }
    }

    return (
        <header className={styles['header']}>
            <Container>
                <div className={`logo ${styles['logo']}`}><Link to={'/'}><Logo /></Link></div>
                <div className="navigation">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">

                        </div>
                    </nav>
                </div>
                <div className={`${styles['user-dropdown']}`}>
                    <Dropdown>
                        <Dropdown.Toggle className={`${styles['btn-secondary']} ${styles['dropdown-toggle']} btn-secondary`} id="dropdown-basic"><span>{auth.first_name}'s konto</span></Dropdown.Toggle>

                        <Dropdown.Menu className={styles['dropdown-menu']}>
                            <li><Dropdown.Item href="#/action-1">My Profile</Dropdown.Item></li>
                            <li><Dropdown.Item onClick={(e) => handleLogout(e)} as={"button"}>Logout</Dropdown.Item></li>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Container>
        </header>
    )
}

export default Header;
