import {Link, useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import LeftSidebar from "./components/LeftSidebar";
import {Toaster} from "react-hot-toast";
import React from "react";

const FrontLayout = ({ children }: any) => {

    const location = useLocation();

    return (
        <>
            <Header />

            <div className={`handling-container`}>
                <Container>
                    <LeftSidebar/>
                    <div className={`handling-right`}>
                        {children}
                    </div>
                </Container>
            </div>

            <Toaster toastOptions={{duration: 3000}} position="top-right" />
        </>
    )
}

export default FrontLayout;
