import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FrontLayout from "../../../components/FrontLayout/Layout";
import Loading from "../../../components/Loading";
import { ReactComponent as UserIcon } from '../../../assets/images/icon-user.svg';
import {get as getAgreement } from "../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../interfaces/IAgreement";
import {useParams} from "react-router-dom";
import IAgreementContact from "../../../interfaces/IAgreementContact";
import BookACallModal from "../Account/Agreements/components/BookACallModal";
import {post as bookAcall} from "../../../services/Apis/Front/BookaCall.service";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

function FrontDashboard() {

    const [isLoading, setIsLoading] = useState(true);
    const [agreement, setAgreement] = useState<IAgreement>();
    let { agreementId } = useParams();
    const [showBookCall, setShowBookCall] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setAgreement(await getAgreement(agreementId));
            setIsLoading(false);
        })();
    }, []);

    const handleBookaCall = async (data: any) => {
        const response = await bookAcall(data);
        setShowBookCall(false);
        toast.success('Your call booked successfully.');
    }

  return (
      <FrontLayout>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <div className={`handling-right-top`}>
                          <Row className={`row row-flex row-flex-wrap`}>
                              <Col xl={7} sm={12} className={`flex-wrap`}>
                                  <div className={`dashboard-middle`}>
                                      <div className={`top-box white-box`}>
                                          <div className={`box-icon icon-1`}></div>
                                          <div className={`h-box-title`}>Boet Efter:</div>
                                          <p>{ agreement?.departed_first_name } { agreement?.departed_last_name }</p>
                                      </div>
                                      <div className={`top-box white-box`}>
                                          <div className={`box-icon icon-2`}></div>
                                          <div className={`h-box-title`}>Antal arvinger</div>
                                          <p>{ agreement?.heirs }</p>
                                      </div>
                                      <div className={`dashboard-bottom white-box`}>
                                          <div className={`dashboard-bottom-data`}>
                                              <h3>Bobehanlding tidslinje</h3>
                                              <p>Step 1: Dødsfald - Begravelse/bisættelse af den afdøde.</p>
                                              <div className={`progress-box`}><span></span></div>
                                              <div className={`progress-text`}>12,5% gennemført</div>
                                              <div className={`btn-row`}><a href="#" className="blue-btn">Gå til tidslinje</a>
                                              </div>
                                          </div>
                                      </div>
                                      <div className={`box-column-2-wrp`}>
                                          <div className={`white-box box-2`}>
                                              <div className={`title`}>Klar til udbetaling</div>
                                              <div className={`box-amount-big mb-4`}>0.00 DKK</div>
                                              <p>Så snart der er et beløb klar til udbetaling, bliver den vist her.</p>
                                              <div className={`box-btn`}><a href="#" className="blue-btn">Få udbetalt nu 💰</a></div>
                                          </div>
                                      </div>
                                      <div className={`column-box-row`}>
                                          <div className={`column-box white-box`}>
                                              <div className={`box-title`}>Arvinger</div>
                                              <div className={`arvinger-data`}>
                                                  <ul>
                                                      {agreement?.contactPersons?.map((contactPerson: IAgreementContact, index: number) => (
                                                          <li><span className="arvinger-user"><UserIcon /></span> { contactPerson.first_name } { contactPerson.last_name }<button onClick={(e) => navigate('/account/aftale/'+agreement.agreement_id+'/arvinger')}></button>&nbsp;</li>
                                                      ))}
                                                  </ul>
                                              </div>
                                          </div>
                                          <div className={`column-box white-box`}>
                                              <div className={`box-title`}>Book et møde med Karva</div>
                                              <div className={`kalender-data`}>
                                                  <p>Har du brug for at tale med Karva-teamet? Book at online møde eller an telefonsamtale her. Du kan også tilføje noter om, hvad det drejer sig om.</p>
                                                  <div className="btn-row"><button onClick={() => setShowBookCall(true)} className="blue-btn">Book at møde</button></div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </Col>
                              <Col xl={5} sm={12}>
                                  <div className="white-box timeline">
                                      <div className="timeline-data">
                                          <ul>
                                              <li className="active">
                                                  <div className="number">1</div>
                                                  <div className="timeline-title icon-1">Start: Dødsfald</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li className="active">
                                                  <div className="number">2</div>
                                                  <div className="timeline-title icon-2">Aftalebrev & Velkomstmail</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li className="active">
                                                  <div className="number">3</div>
                                                  <div className="timeline-title icon-3">Dokumenter underskrives</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">4</div>
                                                  <div className="timeline-title icon-4">Anmodning om Skifteretsattest</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">5</div>
                                                  <div className="timeline-title icon-5">Boet udleveres til Karva</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">6</div>
                                                  <div className="timeline-title icon-6">Intromøde</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">7</div>
                                                  <div className="timeline-title icon-7">Proklamafrist</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">8</div>
                                                  <div className="timeline-title icon-8">Indhentning af dokumenter</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">9</div>
                                                  <div className="timeline-title icon-8">Åbningsstatus</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">10</div>
                                                  <div className="timeline-title icon-8">Afvikling af aktiver/passiver</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">11</div>
                                                  <div className="timeline-title icon-8">Boopgørelse udarbejdes</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">12</div>
                                                  <div className="timeline-title icon-8">Godkendelse fra arvinger</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">13</div>
                                                  <div className="timeline-title icon-8">Skifteret & Skat godkender</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">14</div>
                                                  <div className="timeline-title icon-8">Boafgift beregnes</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">15</div>
                                                  <div className="timeline-title icon-8">Arv fordeles</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                              <li>
                                                  <div className="number">16</div>
                                                  <div className="timeline-title icon-8">Sag afsluttes</div>
                                                  <p>Lorem ipsum dolor amet site.</p>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </Col>
                          </Row>
                      </div>

                      <BookACallModal handleBookingCall={handleBookaCall} show={showBookCall} onHide={() => setShowBookCall(false)} />
                  </>
              }
          </>
      </FrontLayout>
  );
}

export default FrontDashboard;
