import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutWithoutHeader from "../../../components/FrontLayout/LayoutWithoutHeader";
import Container from "react-bootstrap/Container";
import styles from "../../../styles/steps.module.scss";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import heirsInformationSchema from "../../../services/Schemas/Front/Agreement/HeirsInformationSchema";
import {get as getAgreement} from "../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../interfaces/IAgreement";
import {update as updateAgreement} from "../../../services/Apis/Front/Agreements.service";
import Loading from "../../../components/Loading";

function HeirsInformation() {

    const navigate = useNavigate();
    let { agreementId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [heirs, setHeirs] = useState(0);

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(heirsInformationSchema)
    });

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);

            if (response.status != "Agreement Signature Waiting") {
                navigate('/account/aftale/'+ response.agreement_id+'/dashboard', { replace: true });
            }

            setTimeout(() => {
                setFormValues(response);
            }, 100);
            setIsLoading(false);
        })();

        document.body.classList.add('white-background');
    }, []);

    const setFormValues = (agreement: IAgreement) => {
        setHeirs(agreement.heirs - 1);
    }

    const handleFormSubmission = async (data: any) => {
        data.step = 'heirs';
        try {
            const response = await updateAgreement(agreementId, data);
            navigate('/dashboard');
        } catch (err) {
            alert('Something went wrong! Please try again later');
        }
    }

  return (
      <LayoutWithoutHeader>
          <>
          {isLoading &&
              <Loading/>
          }
          {!isLoading &&
              <>
                  <div className={styles['q-step-row']}>
                      <Container className={styles['container']}>
                          <div className={styles['q-step-small-container']}>
                              <form onSubmit={handleSubmit(handleFormSubmission)}>
                                  <div className={`${styles['q-step-title']} text-left`}>Til sidst: Information om
                                      Arvinger.
                                  </div>
                                  <p className={`text-center`}>Vi skal som minimum bruge en email på hver arving,
                                      således at de kan få tilsendt en skiftefuldmagt som skal underskrives. Det er
                                      lovkrav og skal underskrives af hver arving. Du kan også tilføje og redigere
                                      arvinger fra Dashboard senere. </p>
                                  <Row>
                                      {[...Array(heirs)].map((x, i) =>
                                          <Col md={12} sm={12} key={i}>
                                              <div className={styles['form-group']}>
                                                  <input type="email" {...register(`heirs.${i}.email`)}
                                                         className={`form-control ${styles['form-control']}`}
                                                         placeholder={`Email på Arving ${i + 1}`}/>
                                                  <div
                                                      className={styles['invalid-field-error']}>{errors?.heirs?.[i]?.email?.message}</div>
                                              </div>
                                          </Col>
                                      )}
                                  </Row>
                                  <div className={styles['q-steps-btn']}>
                                      <button type="submit" className={`blue-btn ${styles['blue-btn']}`}>GEM &
                                          UNDERSKRIVE DOKUMENTER
                                      </button>
                                  </div>
                              </form>
                          </div>
                      </Container>
                  </div>
              </>
          }
      </>
      </LayoutWithoutHeader>
  );
}

export default HeirsInformation;
