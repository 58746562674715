import { useParams} from "react-router-dom";
import {useLocation} from "react-router-dom";

const LeftSidebar = () => {

    let { agreementId } = useParams();

    const location = useLocation();

    return (
        <div className={`handling-left d-none d-lg-block`}>
            <ul>
                <li className={`${location.pathname.indexOf("/dashboard") !== -1 ? 'active' : ''}`}><a href={`/account/aftale/${agreementId}/dashboard`}><span className="icon-1"></span> Overblik</a></li>
                <li className={`${location.pathname.indexOf("/arvinger") !== -1 ? 'active' : ''}`}><a href={`/account/aftale/${agreementId}/arvinger`}><span className="icon-3"></span> Arvinger</a></li>
                <li className={`${location.pathname.indexOf("/upload-regninger") !== -1 ? 'active' : ''}`}><a href={`/account/aftale/${agreementId}/upload-regninger`}><span className="icon-4"></span> Upload Regninger</a></li>
                <li className={`${location.pathname.indexOf("/dokumenter") !== -1 ? 'active' : ''}`}><a href={`/account/aftale/${agreementId}/dokumenter`}><span className="icon-5"></span> Dokumenter</a></li>
            </ul>
        </div>
    )
}

export default LeftSidebar;
