import React, {useEffect, useCallback, useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import bookaCallSchema from "../../../../../services/Schemas/Front/BookaCallSchema";

function BookACallModal(props: any) {

    const { register, handleSubmit, formState:{ isDirty, isValid, errors }, setValue, watch } = useForm({
        mode: "onChange",
        resolver: yupResolver(bookaCallSchema),
        defaultValues: {
            email: '',
            name: '',
            phone_number: '',
            message: ''
        }
    });

    const handleCall = async (data: any) => {
        props.handleBookingCall(data);
    }

    return (
        <>
            <Modal
                className={`add-document-modal`}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <form onSubmit={handleSubmit(handleCall)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Book et møde
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group mb-3">
                            <label>Navn</label>
                            <input type="text" className="form-control" {...register("name")} />
                            <div className="invalid-feedback-error">{errors?.name?.message?.toString()}</div>
                        </div>
                        <div className="form-group mb-3">
                            <label>Email</label>
                            <input type="text" className="form-control" {...register("email")} />
                            <div className="invalid-feedback-error">{errors?.email?.message?.toString()}</div>
                        </div>
                        <div className="form-group mb-3">
                            <label>Telefon</label>
                            <input type="text" className="form-control" {...register("phone_number")} />
                            <div className="invalid-feedback-error">{errors?.phone_number?.message?.toString()}</div>
                        </div>
                        <div className="form-group mb-3">
                            <label>Kommentarer</label>
                            <textarea className="form-control" {...register("message")}></textarea>
                            <div className="invalid-feedback-error">{errors?.message?.message?.toString()}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={`submit`}>SEND</Button>
                        <Button onClick={props.onHide}>Close</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default BookACallModal;