import axios from "../../../lib/axios";

export async function getAll(id) {
    const response = await axios.get('/agreements/'+id+'/signed-documents');
    return response;
}

export async function get(id, fileid) {
    const response = await axios.get('/agreements/'+id+'/signed-documents/'+fileid, {
        responseType: 'blob'
    });
    return response;
}