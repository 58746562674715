import React, { useState, useEffect } from 'react';
import Dropzone from "react-dropzone";
import axios from "../../../lib/axios";
import { Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";

interface FileProgress {
    [key: string]: string | undefined
}

function InputFile(props:any) {

    const [fileProgress, setFileProgress] = useState<FileProgress>({});

    const acceptedFileTypes = {
        "image/*": [],
        "application/pdf": [],
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
        "application/vnd.ms-excel": [],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
        "application/vnd.ms-powerpoint": [],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
    }

    const onDrop = (acceptedFiles:any) => {
        acceptedFiles.forEach((file:any) => {
            uploadFile(file);
        });
    };

    const uploadFile = async (file:any) => {
        const formData = new FormData();
        formData.append('file', file);

        axios.post('/agreements/'+props.agreementid+'/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.loaded && progressEvent.total) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFileProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: progress,
                    }));
                }
            },
        })
        .then((response) => {
            if (fileProgress?.[file.name]) {
                delete fileProgress?.[file.name];
                setFileProgress(fileProgress);
            }
            toast.success(file.name+' Uploaded Successfully.');
            props.callback();
        })
        .catch((error) => {
            toast.error(file.name+' Failed Upload.');
            console.error('Error uploading file', error);
        });
    };

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <>
            <Row>
                <Col xl={12}>
                    <Dropzone onDrop={onDrop} accept={acceptedFileTypes} multiple={true}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className={`dropzone`}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                        )}
                    </Dropzone>
                </Col>
                <Col xl={12} className={`mt-3`}>
                    <ul className={`w-100`}>
                        {Object.keys(fileProgress).map((fileName: string) => <>
                            { fileProgress[fileName] !== undefined &&
                                <li className={`d-flex file-progress flex-nowrap align-items-center`} key={fileName}>
                                    <span>
                                        {fileName}:&nbsp;&nbsp;
                                    </span>
                                    <div className="progress">
                                        <div style={{width: fileProgress[fileName]+'%'}} className={`progress-bar progress-bar-striped w-${fileProgress[fileName]}`} role={`progressbar`}></div>
                                    </div>
                                </li>
                            }
                        </>)}
                    </ul>
                </Col>
            </Row>
        </>
    );
};

export default InputFile;