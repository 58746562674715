import React, { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FrontLayout from "../../../../components/FrontLayout/Layout";
import Loading from "../../../../components/Loading";
import {get as getAgreement } from "../../../../services/Apis/Front/Agreements.service";
import IAgreement from "../../../../interfaces/IAgreement";
import {Link, useParams} from "react-router-dom";
import {getAll, get, destroy} from "../../../../services/Apis/Front/AgreementDocuments.service";
import Dropzone from "../../Components/Dropzone";
import { ReactComponent as FileIcon } from '../../../../assets/images/icon-file.svg';
import toast from "react-hot-toast";
import FileViewer from 'react-file-viewer';

function AccountAgreementUploadDocuments() {

    const [isLoading, setIsLoading] = useState(true);
    const [showUploadSection, setShowUploadSection] = useState(false);
    const [agreement, setAgreement] = useState<IAgreement>();

    let { agreementId } = useParams();

    const [documents, setDocuments] = useState([]);

    const getDocuments = async () => {
        const response = await getAll(agreementId);
        setDocuments(response.data);
    };

    const downloadFile = async (fileid: number, filename: string) => {
        setIsLoading(true);
        const response = await get(agreementId, fileid);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a download link for the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);  // Set the file name

        // Append the link to the document body, trigger a click to start the download, then remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsLoading(false);
    };

    const deleteFile = async (fileid: number, filename: string) => {
        setIsLoading(true);
        await destroy(agreementId, fileid);
        await getDocuments();
        toast.success(filename+' Deleted Successfully.');
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            const response = await getAgreement(agreementId);
            await getDocuments();
            setAgreement(response);
            setIsLoading(false);
        })();
    }, []);

  return (
      <FrontLayout>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <Row className={`row-flex row-flex-wrap`}>
                          <Col xl={12}>
                              <div className="arv-top white-box nopad">
                                  <div className="h-box-title">Upload Regninger</div>
                                  <div className="arv-top-btn"><button type={`button`} onClick={() => setShowUploadSection(showUploadSection === false)} className={`blue-btn`}>{ showUploadSection === false ? '+ Opret dokumenter' : '- Luk dokumenter' }</button></div>
                              </div>
                          </Col>
                      </Row>
                      { showUploadSection === true &&
                          <Row className={`row-flex row-flex-wrap mt-4`}>
                              <Col xl={12}>
                                  <Dropzone callback={getDocuments} agreementid={agreementId} />
                              </Col>
                          </Row>
                      }
                      <Row>
                          <Col xl={12}>
                              <div className="white-box arv-form-box document-list">
                                  <Row>
                                      {documents.length <= 0 &&
                                          <h5 className={`pt-4 text-center`}>There are no documents to display.</h5>
                                      }

                                      {documents?.map((document:any, index: number) => (
                                          <Col xl={4} className={`document-item`} key={index}>
                                              <div className="card">
                                                  <div className={`preview`} style={{  }}>
                                                      <FileViewer
                                                          fileType={document.file_content_type}
                                                          filePath={`${document.file_url}?timestamp=${new Date().getTime()}`}
                                                          key={document.id}
                                                      />
                                                  </div>
                                                  <div className="card-body">
                                                      <h5 className="card-title">{ document.file_file_name }</h5>
                                                  </div>
                                                  <ul className="list-group list-group-flush">
                                                      <li className="list-group-item">
                                                          <button type={"button"} onClick={() => deleteFile(document.id, document.file_file_name)} className={`small-btn delete`} title={'Slet'}></button>
                                                          <button type={"button"} onClick={() => downloadFile(document.id, document.file_file_name)} className={`small-btn download`} title={'Download'}></button>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </Col>
                                      ))}
                                  </Row>
                              </div>
                          </Col>
                      </Row>
                  </>
              }
          </>
      </FrontLayout>
  );
}

export default AccountAgreementUploadDocuments;
